module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: 'Pedro Almeida - Personal Website', // Navigation and Site Title
  titleAlt: 'Pedro Almeida', // Title for JSONLD
  description: 'Portfolio and Blog website where I showcase my projects, curriculum and whatever else I want.',
  headline: 'Pedro Almeida | Personal Website', // Headline for schema.org JSONLD
  url: 'https://www.ptalmeida.com', // Domain of your site. No trailing slash!
  logo: '/logos/logo.png', // Used for SEO
  ogLanguage: 'en_US', // Facebook Language

  // JSONLD / Manifest
  favicon: 'src/favicon.png', // Used for manifest favicon generation
  shortName: 'PTAlmeida', // shortname for manifest. MUST be shorter than 12 characters
  author: 'Pedro Almeida', // Author for schemaORGJSONLD
  authorEmail: 'mailto:contact@ptalmeida.com', // Author for schemaORGJSONLD
  themeColor: '#1D809F',
  backgroundColor: '#ecb807',

  twitter: '@ptalmeida', // Twitter Username
  facebook: 'example', // Facebook Site Name
  googleAnalyticsID: 'UA-144015848-1',

  skipNavId: 'reach-skip-nav', // ID for the "Skip to content" a11y feature
}
