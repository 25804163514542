import React from 'react'
import Img from 'gatsby-image'
import styled from '@emotion/styled'
import { LocaleContext } from './Layout'
import locales from '../../config/i18n'
import { ContactItems, CVbutton } from '../components'
import Wave from './Wave'

const StyledAbout = styled.div`
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  padding: 2rem;
  background: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.bg};
  text-align: center;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding-bottom: ${props => props.theme.headerHeight.s};
  }

  p, h2 {
    margin: 0 auto;
    color: ${props => props.theme.colors.bg};
  }

  > div:not(:first-of-type) {
    margin-top: 1rem;

    > span::after{
      content: "";
      position: absolute;
      top: 0;
      height: 2px;
      left: 50%;
      transform: translateX(-50%);
      width: 1rem;
      background: ${props => props.theme.colors.bg};
    }
  }

  * {
    margin: 0 auto;
    max-width: ${props => props.theme.maxWidth};
  }
`

const AboutElement = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-flow: column nowrap;
  
  p {
    margin: 0;
    font-family: 'Montserrat', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  }
`

const AboutSplit = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-flow: row nowrap;
  gap: 40px;
  margin: 4rem !important;

  > div {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
  }

  > div:not(:first-child) {
    width: 250px;
    min-width: 250px;
  }

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin: 0 !important;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    flex-flow: column nowrap;
  }
`

const ElementTitle = styled.span`
  position: relative;
  padding-bottom: 1rem;
  font-size: 2.369rem;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 1.777rem;
  }
`

const FirstWave = styled.div`
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    display: none;
  }
`

const ImgWrapper = styled.div`
  overflow: hidden;
  width: 80%;
  border-radius: 40rem;
  margin-bottom: 1rem;
`

const About = ({ picture }) => 
{ 
  const { locale } = React.useContext(LocaleContext)

  return(                 
    <>
      <FirstWave><Wave animationSpeed={"3s"} reversed={true} type={1}/></FirstWave>
      <StyledAbout>
        <AboutSplit>
          <div>
            <ElementTitle>
              <h2>{locales[locale].pages.about.whoAmI}</h2>
            </ElementTitle>
            <AboutElement>
              <p>{locales[locale].pages.about.whoAmIAnswer}</p>
              <p>{locales[locale].pages.about.whatILike}</p>
            </AboutElement>
            <ContactItems/>
          </div>
          <div>
            <ImgWrapper>
              <Img ClassName={"picture"} fluid={picture} />
            </ImgWrapper>
            <CVbutton/>
          </div>
        </AboutSplit>

      </StyledAbout>
      <Wave animationSpeed={"3s"} type={1}/>
    </>
  )}

export default About