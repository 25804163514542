import React from 'react'

import LocaleWrap from './wrapPageElement'

if (typeof window !== "undefined") {
    console.log(
        `%c
          _________           
         | 👁️      |          
         |      👁 |          
         |    👃   |          
         |  👅     |          
         |_________|          
            💪👔🤜           
   MEQUIÉ?    👖              
                             
   contact@ptalmeida.com     
   www.ptalmeida.com/contact 
                              `
    , "color: white; font-size:15px; background: black;");
}

export const wrapPageElement = LocaleWrap
