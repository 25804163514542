import styled from '@emotion/styled'

const Headline = styled.div`
  display:flex;
  flex-flow: row wrap;
  font-family: 'Montserrat', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  color: ${props => props.theme.colors.primary};
  margin-bottom: 0;

  >div{
    margin-right: 0.5rem;
  }
  a {
    color: ${props => props.theme.colors.primary};
    font-style: normal;
    font-weight: normal;
  }
  span :not(:first-of-type) {
    margin-left: 0.5rem;
  }
`

export default Headline