import React from 'react'
import PropTypes from 'prop-types'
import Categories from '../Categories'
import ListingContainer from '../ListingContainer'
import ListingLink from '../ListingLink'
import DescriptionPair from '../DescriptionPair'
import Headline from '../../Headline'
import { LocaleContext } from '../../Layout'
import locales from '../../../../config/i18n'

import {DateRange as DateIcon} from 'emotion-icons/material'

const PostListItem = ({ node, categories }) => {
  const { locale } = React.useContext(LocaleContext)

  return  <ListingContainer>
            <Headline>
              <DescriptionPair icon={<DateIcon/>} content={node.data.date}/>
              <Categories categories={categories}/>
            </Headline>
            <ListingLink to={`/${locales[locale].pages.blog.path}/${node.uid}`}><span>{node.data.title.text}</span></ListingLink>
            <p>{node.data.description}</p>
          </ListingContainer>
}

export default PostListItem

PostListItem.propTypes = {
  node: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
}
