import React from 'react'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Categories from '../Categories'
import ListingContainer from '../ListingContainer'
import ListingLink from '../ListingLink'
import LocalizedLink from '../../LocalizedLink'
import Headline from '../../Headline'
import { LocaleContext } from '../../Layout'
import locales from '../../../../config/i18n'

const Item = styled(ListingContainer)`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  height: 40vh;
  padding: 0;

  :nth-of-type(2n-1) {
    flex-flow: row nowrap;
    justify-content: flex-start;

    @media (max-width: ${props => props.theme.breakpoints.s}) {
      flex-flow: column;
      > * {
        :after {
          opacity: 0;
        }
      }
    }

    > * {
      :after {
        left: 0;
        right: 50%;
      }
    }
  }

  :nth-of-type(2n) {
    flex-flow: row-reverse nowrap;
    justify-content: space-between;

    @media (max-width: ${props => props.theme.breakpoints.s}) {
      flex-flow: column;
      > * {
        :after {
          opacity: 0;
        }
      }
    }
  
    > * {
      :after {
        left: 50%;
        right: 0;
      }
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    height: 100%;
    max-height: 80vh;
  }
`

const ImgWrapper = styled(LocalizedLink)`
  overflow: hidden;
  width: 50%;
  min-width: 50%; 
  border-radius: 1rem;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    width: 100%;
    min-width: unset;  
  }

  > * {
    transition: transform .3s ease-in-out;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  img {
    object-position: top center !important;
  }

  :hover > * {
    transform: scale(1.2);
  }

  ::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: -1;
    border-radius: 1rem;
    opacity: 0.4;
    box-shadow: inset 7px 7px 14px ${props => props.theme.colors.secondary_outset_1}, inset -7px -7px 14px ${props => props.theme.colors.bg_outset_2};
    transition: opacity 0.25s ease;
  }

  :hover {
    :after {
      opacity: 0;
    }
  }
`

const TextWrapper = styled.div`
  padding: 1rem;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding: 1rem;
  }
`


const ProjectListItem = ({ node, categories }) => {
  const { locale } = React.useContext(LocaleContext)

  return <Item>
    <ImgWrapper to={`/${locales[locale].pages.projects.path}/${node.uid}`}>
      <Img ClassName={"thumb"} fluid={node.data.image.localFile.childImageSharp.fluid} />
    </ImgWrapper>
    <TextWrapper>
      <Headline>
        <Categories categories={categories}/>
      </Headline>
      <ListingLink to={`/${locales[locale].pages.projects.path}/${node.uid}`}><span>{node.data.title.text}</span></ListingLink>
      <p>{node.data.description}</p>
    </TextWrapper>
  </Item>
}

export default ProjectListItem

ProjectListItem.propTypes = {
  node: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
}
