import React from 'react'
import styled from '@emotion/styled'
import { CoolLink } from '../'

const DescriptionCool = styled.div`
  div{
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    padding: 0;
    margin: 0;
    a {
      color: ${props => props.theme.colors.primary};
    }
    a::after {
      top: 85%;
      background-color: ${props => props.theme.colors.primary};
    }
  }
`
const DescriptionCoolLink = ({ children }) => (
  <DescriptionCool>
    <CoolLink>
      {children}
    </CoolLink>
  </DescriptionCool>
)

export default DescriptionCoolLink