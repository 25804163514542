import React from 'react'
import { css } from '@emotion/core'
import Img from 'gatsby-image'

const imgCss = css`
    max-height: 80vh;
    border-radius: 1rem;
    //box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`
// <StyledFooter>{children}</StyledFooter>
const CustomImg = ({ input }) => <Img css={imgCss} fluid={input}></Img>

export default CustomImg
