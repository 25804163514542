const baseTheme = {
  transition: '.2s cubic-bezier(0.4, 0, 0.2, 1)',
  maxWidth: '1100px',
  maxWidthText: '720px',
  headerHeight: {
    xs: '60px',
    s: '60px',
    m: '60px',
    l: '60px',
  },
  breakpoints: {
    xs: '400px',
    s: '600px',
    m: '900px',
    l: '1200px',
  },
};

// https://mycolor.space/?hex=%23FF9F1C&sub=1
export const themeLight = {
  ...baseTheme,
  isDark: false,
  colors: {
    text: {
      p: '#000',
      pFooter: '#595C62',
    },
    primary: '#0f2f7f',
    secondary: '#1748c3',
    secondary_outset_1: '#113590',
    secondary_outset_2: '#1d5bf6',
    bg: '#EFEFEF',
    bg_outset_1: '#b8b8b8',
    bg_outset_12: '#606060',
    bg_outset_2: '#ffffff',
    bg_outset_22: '#ffffff',
    white: '#fff',
    black: '#000',
    grey: '#595C62',
  },
  svg: {
    wavePrimary: 'lightWavePrimary.svg',
    waveSecondary: 'lightWaveSecondary.svg',
  },
};

// https://colorhunt.co/palette/43636
export const themeDark = {
  ...baseTheme,
  isDark: true,
  colors: {
    text: {
      p: '#EFEFEF',
      pFooter: '#53354a',
    },
    primary: '#ff7844',
    secondary: '#a64942',
    secondary_outset_1: '#113590',
    secondary_outset_2: '#1d5bf6',
    bg: '#1b1f3a',
    bg_outset_1: '#14172b',
    bg_outset_12: '#0b0c17',
    bg_outset_2: '#222749',
    bg_outset_22: '#2b325d',
    white: '#000',
    black: '#fff',
    grey: '#595C62',
  },
  svg: {
    wavePrimary: 'darkWavePrimary.svg',
    waveSecondary: 'darkWaveSecondary.svg',
  },
};