import React from 'react'
import styled from '@emotion/styled'
import { LocaleContext } from './Layout'
import locales from '../../config/i18n'
import CoolLink from './CoolLink'

const CVDownload = styled.div`
  background: ${props => props.theme.colors.bg};
  margin: 0.5rem auto;
  padding: 0.5rem;
  border-radius: 0.5rem;
  height: fit-content;
  width: fit-content;

  a {
    font-size: 1.5rem;
    color: ${props => props.theme.colors.primary};
    font-family: 'Montserrat', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  }
  a::after {
    top: 80% !important;
    background-color: ${props => props.theme.colors.primary};
  }
`

const CVbutton = () => 
{ 
  const { locale } = React.useContext(LocaleContext)

  return(                 
    <CVDownload>
      <CoolLink>
        <a target="_blank" rel="noopener noreferrer"  href={locales[locale].pages.about.getCVLink}>{locales[locale].pages.about.getCV}</a>
      </CoolLink>
    </CVDownload>
  )
}

export default CVbutton