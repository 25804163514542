import React from 'react'
import PropTypes from 'prop-types'
import DescriptionCoolLink from './DescriptionCoolLink'
import DescriptionPair from './DescriptionPair'
import LocalizedLink from '../LocalizedLink'
import { LocaleContext } from '../Layout'
import locales from '../../../config/i18n'

import {LabelOutline as CategoryIcon} from 'emotion-icons/material'

const compareNames = ( a, b ) => {
  const nameA = a.data.name.toUpperCase();
  const nameB = b.data.name.toUpperCase();

  let comparison = 0;
  if (nameA > nameB) {
    comparison = 1;
  } else if (nameA < nameB) {
    comparison = -1;
  }
  return comparison;
}

const Categories = ({ categories }) => {
  const { locale } = React.useContext(LocaleContext)
  //categories.sort(compareNames);

  return <>
    <DescriptionPair icon={<CategoryIcon/>} content=
      {categories.map((cat, i) => (
        <React.Fragment key={cat.uid}>
          {!!i && ',\u00A0'}
          <DescriptionCoolLink>
            <LocalizedLink to={`/${locales[locale].pages.categories.path}/${cat.uid}`}>{cat.data.name}</LocalizedLink>
          </DescriptionCoolLink>
        </React.Fragment>
      ))}
    />
  </>
}

export default Categories

Categories.propTypes = {
  categories: PropTypes.array.isRequired,
}
