import React from 'react'
import styled from '@emotion/styled'
import { LocalizedLink, CoolLink } from './'
import { LocaleContext } from './Layout'
import locales from '../../config/i18n'

const HeaderWrapper = styled.header`
  display: flex;
  background-color: ${props => props.theme.colors.primary};
  position: fixed;
  width: 100%;
  overflow: hidden;
  z-index: 2;
  height: ${props => props.theme.headerHeight.l};
  top: 0;
  bottom: unset;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    height: ${props => props.theme.headerHeight.s};
    top: unset;
    bottom: -1px;
  }
`

const HeaderNav = styled.nav`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
  max-width: ${props => props.theme.maxWidth};
  margin: 0 auto;
  padding: 0 2rem;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding: 0 1.5rem;
  }
  > * {
    padding: 0;
    margin: 0;
  }
`

const ItemList = styled.ul`
  display: flex;
  > div :not(:first-of-type) {
    margin-left: 1rem;
  }  
  div{
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    padding: 0;
    margin: 0;
    a {
      color: ${props => props.theme.colors.bg};
      font-family: 'Montserrat', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
      sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    }
    a::after {
      top: 65%;
      background-color: ${props => props.theme.colors.bg};
    }
    :first-of-type{
      a {
        font-weight: 700;
      }
    }
    @media (max-width: ${props => props.theme.breakpoints.xs}) {
      a {
        font-size: 0.8rem;
      }
    }
  }
`

const Header = ({ children }) => 
{
  const { locale } = React.useContext(LocaleContext)

  return (
  <HeaderWrapper>
    <HeaderNav>
      <ItemList role="navigation">
        <CoolLink>
          <LocalizedLink to="/" aria-label={locale === undefined ? "Pedro." : locales[locale].pages.home.name}>
            {locale === undefined ? "Pedro." : locales[locale].pages.home.name}
          </LocalizedLink>
        </CoolLink>
        <CoolLink>
          <LocalizedLink to="/projects" aria-label={locale === undefined ? "Projects" : locales[locale].pages.projects.name}>
            {locale === undefined ? "Projects" : locales[locale].pages.projects.name}
          </LocalizedLink>
        </CoolLink>
        <CoolLink>
          <LocalizedLink to="/blog" aria-label={locale === undefined ? "Blog" : locales[locale].pages.blog.name}>
            {locale === undefined ? "Blog" : locales[locale].pages.blog.name}
          </LocalizedLink>
        </CoolLink>
        <CoolLink>
          <LocalizedLink to="/contact" aria-label={locale === undefined ? "Reach Me" : locales[locale].pages.contact.name}>
            {locale === undefined ? "Contact" : locales[locale].pages.contact.name}
          </LocalizedLink>
        </CoolLink>
      </ItemList>
      { children }
    </HeaderNav>
  </HeaderWrapper>
  )
}

export default Header