const website = require('./website')

module.exports = {
  'pt-pt': {
    path: 'pt',
    pages: {
      home: {
        path: '',
        name: 'Pedro.',
        title: '',
      },
      about: {
        path: 'about',
        name: 'Sobre mim',
        title: '',
        whoAmI: 'Olá!',
        whoAmIAnswer: 'O meu nome é Pedro Almeida, sou mestrado en engenharia informática pela FCT UNL.',
        whatILike: '',
        getCV: 'Vê o meu CV!',
        getCVLink: '/PedroAlmeidaCVpt.pdf',
      },
      categories: {
        path: 'categories',
        name: 'Categorias',
        title: 'Todas as categorias',
        category: 'Categoria',
      },
      contact: {
        path: 'contact',
        name: 'Contacto',
        title: 'Contacto',
      },
      blog: {
        path: 'blog',
        name: 'Blog',
        title: 'Blog',
        recent: 'Posts recentes',
        other: 'Outros Posts',
      },
      projects: {
        path: 'projects',
        name: 'Projectos',
        title: 'Os Meus Projectos',
        recent: 'Projectos recentes',
        other: 'Outros Projectos',
      },
    },
    locale: 'pt-pt',
    siteLanguage: 'pt',
    ogLang: 'pt_PT',
    defaultTitle: website.title,
    defaultTitleAlt: website.titleAlt,
    defaultDescription: 'Site em portugues',
    headline: 'Headline em portugues',
    thereAre: 'Há',
    madeWith: 'feito com',
    madeWithPl: 'feitos com',
    total: 'no total',
    recent: 'Recentes',
    other: 'Outros',
    entries: 'entradas',
  },
  'en-gb': {
    path: 'en',
    pages: {
      home: {
        path: '',
        name: 'Pedro.',
        title: '',
      },
      about: {
        path: 'about',
        name: 'About Me',
        title: '',
        whoAmI: 'Hello there!',
        whoAmIAnswer: 'I\'m Pedro Almeida, a MSc. in Computer Science and Engineering from Portugal.',
        whatILike: 'I enjoy learning new technologies and whatever is new in the CS world. I\'m currently interested in developing Full-Stack applications. Please check out my CV and projects for details!',
        getCV: 'Get my CV!',
        getCVLink: '/PedroAlmeidaCVen.pdf',
      },
      categories: {
        path: 'categories',
        name: 'Categories',
        title: 'All categories',
        category: 'Category',
      },
      contact: {
        path: 'contact',
        name: 'Contact',
        title: 'Contact Me',
      },
      blog: {
        path: 'blog',
        name: 'Blog',
        title: 'Blog',
        recent: 'Recent Posts',
        other: 'Other Posts',
      },
      projects: {
        path: 'projects',
        name: 'Projects',
        title: 'My Projects',
        recent: 'Recent Projects',
        other: 'Other Projects',
      },
    },
    default: true,
    locale: 'en-gb',
    siteLanguage: 'en',
    ogLang: 'en_US',
    defaultTitle: website.title,
    defaultTitleAlt: website.titleAlt,
    defaultDescription: website.description,
    headline: website.headline,
    thereAre: 'There are',
    madeWith: 'made with',
    madeWithPl: 'made with',
    total: 'total',
    recent: 'Recent',
    other: 'Other',
    entries: 'entries',
  },
}
