import styled from '@emotion/styled'
import LocalizedLink from '../LocalizedLink'

const ListingLink = styled(LocalizedLink)`
  width: fit-content;
  font-family: 'Montserrat', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
  sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 2.369rem;
  font-style: normal;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    font-size: 1.777rem;
  }
  background-image: linear-gradient(120deg, ${props => props.theme.colors.primary} 0%, ${props => props.theme.colors.primary} 100%);
  background-repeat: no-repeat;
  background-size: 100% 0.2em;
  background-position: 0 88%;
  transition: background-size 0.25s ease-in;

  span {
    background-image: linear-gradient(${props => props.theme.colors.primary} 50%, ${props => props.theme.colors.bg} 0%);
    background-repeat: no-repeat;
    background-size: 100% 300%;
    background-position: 50% 25%;
    transition: background-position 0.25s ease-in;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 0.4px;
    -webkit-text-stroke-color: ${props => props.theme.colors.bg};
  }

  &:hover {
    background-size: 100% 88%;
    span {
      background-position: 50% 100%;
    }
  }
  &:hover,
  &:focus {
    color: white;
    text-decoration: none;
  }
`

export default ListingLink