import styled from '@emotion/styled'
import withReveal from 'react-reveal/withReveal';
import Slide from 'react-reveal/Fade';

const ListingContainer = withReveal(styled.li`
    z-index: 0;
    height: 100%;
    width: 100%;
    padding: 1rem;
    position: relative;
    border: 10px solid transparent;
    background-clip: padding-box;
    background-color: transparent;

    ::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        box-shadow: 9px 9px 14px ${props => props.theme.colors.bg_outset_1}, -9px -9px 14px ${props => props.theme.colors.bg_outset_2};
        z-index: -1;
        border-radius: 1rem;
    }
`, <Slide delay={100} duration={300} bottom /> );

export default ListingContainer