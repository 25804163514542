import React from 'react'
import styled from '@emotion/styled'

const LinkWrapper = styled.div`
    align-items: center;
    position: relative;
    cursor: pointer;
    padding: 0;
    margin: 0;
    
  a {
    font-weight: 400;
    font-style: normal;
  }

  a,
  a:hover,
  a:focus,
  a:active {
    text-decoration: none;
  }

  a::after {
    content: "";
    position: absolute;
    top: 65%;
    height: 2px;
    width: 0%;
    left: 0%;
    opacity: 0;
    transition: 0.2s ease-in 0.2s;
  }


  :hover a::after {
    left: 5%;
    opacity: 1;
    width: 90%;
    transition: 0.2s ease-in;
  }
`

const CoolLink = ({ children }) => 
{
  return (
  <LinkWrapper>
    { children }
  </LinkWrapper>
  )
}

export default CoolLink