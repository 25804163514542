import React from 'react'
import styled from '@emotion/styled'
import { LocaleContext } from './Layout'
import CoolLink from './CoolLink'
import { GitHub , Gmail, LinkedIn } from 'emotion-icons/simpleIcons'

const ContactItemsWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  padding-top: 1rem;
  justify-content: center;
  
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    flex-flow: row wrap;
    align-items: center;
  }
  > * {
    padding: 0.5rem;
    border-radius: 0.5rem;
    margin: 0.5rem;
    width: fit-content;
  }
  a {
    color: ${props => props.theme.colors.primary};
    font-family: 'Montserrat', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  }
  a::after {
    top: 80% !important;
    background-color: ${props => props.theme.colors.primary};
  }
`

const Item = styled.div`
  display: flex;
  flex-flow: row nowrap;
  background: ${props => props.theme.colors.bg};
  svg {
    margin: 0 0.5rem 0 0;
  }
`


const ContactItems = ({ reverse = false }) => 
{ 
  return(                 
    <ContactItemsWrapper>
      <Item>
        <Gmail/>
        <CoolLink>
          <a href="mailto:contact@ptalmeida.com" target="_blank">Email</a>
        </CoolLink>
      </Item>
      <Item>
        <LinkedIn/>
        <CoolLink>
          <a href="https://www.linkedin.com/in/ptalmeida" target="_blank">LinkedIn</a>
        </CoolLink>
      </Item>
      <Item>
        <GitHub/>
        <CoolLink>
          <a href="https://www.github.com/ptalmeida" target="_blank">Github</a>
        </CoolLink>
      </Item>
    </ContactItemsWrapper>
  )
}

export default ContactItems