import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import ProjectListItem from './ProjectListItem'

const List = styled.ul`
  margin-top: 2rem;
  margin-bottom: 4rem;
  list-style-type: none;
  margin-left: 0;
  display: flex;
  flex-flow: row wrap;
  align-items:center;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-template-areas: 
      "top"
      "center";
      "bottom";
  }

  > * {
    width: 100%;
  }
`

const ProjectListing = ({ projects }) => (
  <List>
    {projects.map(project => {
      let categories = false
      if (project.node.data.categories[0].category) {
        categories = project.node.data.categories.map(c => c.category.document[0])
      }
      return <ProjectListItem key={project.node.uid} node={project.node} categories={categories} />
    })}
  </List>
)

export default ProjectListing

ProjectListing.propTypes = {
  projects: PropTypes.array.isRequired,
}
