import styled from '@emotion/styled'
import Wave from './Wave'

const HeroHeaderWrapper = styled.header`
    background-color: ${props => props.theme.colors.primary};
    padding-top: 1rem;
    h1 {
        color: ${props => props.theme.colors.bg};
    }
`

const HeroHeaderContentWrapper = styled.div`
    padding-bottom: 1rem;
`

const HeroWaveWrapper = styled.div`
    background-color: ${props => props.theme.colors.bg};
`

const DefaultHero = ({ children }) => 
<HeroHeaderWrapper>
    <HeroHeaderContentWrapper>{ children }</HeroHeaderContentWrapper>
    <HeroWaveWrapper><Wave animationSpeed={"4s"} type={1}/></HeroWaveWrapper>
</HeroHeaderWrapper>


export default DefaultHero
