import React from 'react'
import styled from '@emotion/styled'
import { css, keyframes } from '@emotion/core'

const WaveWrapper = styled.div`
  height: 30px;
  width: 100%;
  top:0;
  left:0;
  overflow: hidden;
`

const WaveElemPrimary = styled.div`
  top: calc(${props => props.theme.headerHeight.l} + 30px);
  background: url(../${props => props.theme.svg.wavePrimary}) repeat-x; 
  width: calc(100vw + 100px);
  height: 30px;
  transform: translate3d(0, 0, 0);
`

const WaveElemSecondary = styled.div`
  top: calc(${props => props.theme.headerHeight.l} + 30px);
  background: url(../${props => props.theme.svg.waveSecondary}) repeat-x; 
  width: calc(100vw + 100px);
  height: 30px;
  transform: translate3d(0, 0, 0);
`

const waveAnim = keyframes`
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -100px;
  }
`

const waveAnimReversed = keyframes`
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: 100px;
  }
`

const Wave = ({ animationSpeed, reversed, type }) => 
<WaveWrapper>
  { reversed && type == 1 &&
    <WaveElemPrimary css={css`
        animation: ${waveAnimReversed} ${animationSpeed} cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
        transform: scaleY(-1) translateX(-100px);
      `}/>
    }
  { reversed && type == 2 &&
    <WaveElemSecondary css={css`
        animation: ${waveAnimReversed} ${animationSpeed} cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
        transform: scaleY(-1) translateX(-100px);
      `}/>
    }
  { !reversed && type == 1 &&
    <WaveElemPrimary css={css`
        animation: ${waveAnim} ${animationSpeed} cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
      `}/>}
  { !reversed && type == 2 &&
    <WaveElemSecondary css={css`
        animation: ${waveAnim} ${animationSpeed} cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
      `}/>}
</WaveWrapper>

export default Wave;