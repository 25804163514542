/* eslint no-unused-expressions: 0 */
/* eslint react/destructuring-assignment: 0 */

import React, { useState } from 'react';
import PropTypes from 'prop-types'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { Global, css } from '@emotion/core'
import styled from '@emotion/styled'
import { ThemeProvider, useTheme } from 'emotion-theming'
import '@reach/skip-nav/styles.css'

import { Footer, Header } from './'
import SkipNavLink from './SkipNavLink'
import { themeLight, themeDark, reset } from '../styles'
import i18n from '../../config/i18n'

import {Moon as MoonIcon, Sun as SunIcon} from 'emotion-icons/feather'
import ptFlag from '../images/pt-flag.svg'
import enFlag from '../images/uk-flag.svg'

import 'typeface-montserrat'
import 'typeface-source-sans-pro'

// https://www.modularscale.com/?1&rem&1.333
const globalStyles = theme => css`
  ${reset}
  h1, h2, h3, h4, h5, h6 {
    color: ${theme.colors.primary};
  }
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body {
    color: ${theme.colors.text.p};
    background-color: ${theme.colors.bg};
  }
  ::selection {
    color: ${theme.colors.bg};
    background-color: ${theme.colors.primary};
  }
  a {
    color: ${theme.colors.primary};
    transition: all 0.4s ease-in-out;
    text-decoration: none;
    font-weight: 700;
    font-style: italic;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
  @media (max-width: ${theme.breakpoints.m}) {
    html {
      font-size: 16px !important;
    }
  }
  @media (max-width: ${theme.breakpoints.s}) {
    h1 {
      font-size: 2.369rem !important;
    }
    h2 {
      font-size: 1.777rem !important;
    }
    h3 {
      font-size: 1.333rem !important;
    }
    h4 {
      font-size: 1rem !important;
    }
    h5 {
      font-size: 0.75rem !important;
    }
    h6 {
      font-size: 0.563rem !important;
    }
  }
`

const Main = styled.div`
  padding-top: ${props => props.theme.headerHeight.l};
  padding-bottom: 0;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding-top: ${props => props.theme.headerHeight.m};
    padding-bottom: 0;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding: 0;
  }
`

const Toggles = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  > * {
    height: 30px;
    width: 30px;
    padding: 0;
    margin: 0 0.5rem;
  }
`

const LocaleSwitcher = styled.div`
  > *, *:hover, *:focus {
    font-style: normal;
    font-size: 1.5rem;
    text-decoration: none;
  }
  > *:hover {
    text-decoration: none;
  }
`

// https://codepen.io/sosuke/pen/Pjoqqp
const DarkModeButton = styled.button`
  transition: color 0.2s ease-in;
  cursor: pointer;
  border: none;
  outline: none;
  background: transparent;
  color: ${props => props.theme.colors.bg};
  &:focus {
    outline: none;
  }
`

const SVGIconStyles = css`
  height: inherit;
  width: inherit;
  fill: currentColor;
  transition: 0.2s ease-in-out;
  :hover {
    transform: rotate(90deg);
  }
`

const LocaleContext = React.createContext()

const Layout = ({ children, pageContext: { locale } }) => {
  const [isDark, setIsDark] = useState(false);
  const data = useStaticQuery(query)
  const footer = data.allPrismicHomepage.edges
    .filter(edge => edge.node.lang === locale)
    .map(r => r.node.data.footer.html)
    .toString()

    const localeLangToShow = locale == "en-gb" ? "pt-pt" : "en-gb";
    const localeFlagToShow = localeLangToShow == "en-gb" ? enFlag : ptFlag;
    let localeLangToPath = "";

    //circumvent gatsby SSR window problem
    if (typeof window !== 'undefined') {
      localeLangToPath = localeLangToShow == "en-gb" 
            ? window.location.pathname.replace("/pt", "")  
            : "/pt" + window.location.pathname
    }

    localeLangToPath = localeLangToPath == "" ? "/": localeLangToPath;
    localeLangToPath = localeLangToPath == "/pt/" ? "/pt": localeLangToPath;

  return (
    <LocaleContext.Provider value={{ locale, i18n }}>
      <ThemeProvider theme={isDark ? themeDark : themeLight}>
        <>
          <Global styles={globalStyles} />
          <SkipNavLink />
          <Header>
            <Toggles>
              <DarkModeButton
                onClick={() => {
                  setIsDark(!isDark);
                }}
                aria-label="Toggle Dark Mode Button">
                {isDark ? ( <SunIcon css={SVGIconStyles} /> ) : ( <MoonIcon css={SVGIconStyles}/> )}
              </DarkModeButton>
              <LocaleSwitcher data-name="locale-switcher">
                <Link hrefLang={localeLangToShow} to={localeLangToPath}>
                  <img src={localeFlagToShow} alt={localeLangToShow} css={css`vertical-align: baseline;`}/>
                </Link>
              </LocaleSwitcher>
            </Toggles>
          </Header>
          <Main>
            {children}
          </Main>
          <Footer>
            <div dangerouslySetInnerHTML={{ __html: footer }} />
          </Footer>
        </>
      </ThemeProvider>
    </LocaleContext.Provider>
  )
}

export { LocaleContext, Layout }

const query = graphql`
  query LayoutQuery {
    allPrismicHomepage {
      edges {
        node {
          lang
          data {
            footer {
              html
            }
          }
        }
      }
    }
  }
`

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
}
