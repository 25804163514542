import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const StyledFooter = styled.footer`
  max-width: ${props => props.theme.maxWidth};
  margin: 6rem auto 0 auto;
  padding: 2rem;
  color: ${props => props.theme.colors.text.pFooter};
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    margin: 2rem auto 1rem auto;
    padding-bottom: ${props => props.theme.headerHeight.s};
  }
`
// <StyledFooter>{children}</StyledFooter>
const Footer = ({ children }) => <StyledFooter>© {new Date().getFullYear()} Pedro Almeida</StyledFooter>

export default Footer

Footer.propTypes = {
  children: PropTypes.node.isRequired,
}
