import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const DescriptionItm = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  *, svg {
    margin: 0;
    vertical-align: middle;
  }
  svg {
    margin-right: 0.2rem;
  }
`

const DescriptionPair = ({ icon, content }) => (
  <DescriptionItm>
    {icon}{content}
  </DescriptionItm>
)

export default DescriptionPair

DescriptionPair.propTypes = {
  Icon: PropTypes.node,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
}