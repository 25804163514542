import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import PostListItem from './PostListItem'

const List = styled.ul`
  margin-top: 2rem;
  margin-bottom: 4rem;
  list-style-type: none;
  margin-left: 0;
  display: flex;
  flex-flow: row wrap;
  align-items:center;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-template-areas: 
      "top"
      "center";
      "bottom";
  }

  > * {
    width: 100%;
  }
`

const PostListing = ({ posts }) => (
  <List>
    {posts.map(post => {
      let categories = false
      if (post.node.data.categories[0].category) {
        categories = post.node.data.categories.map(c => c.category.document[0])
      }
      return <PostListItem key={post.node.uid} node={post.node} categories={categories} />
    })}
  </List>
)

export default PostListing

PostListing.propTypes = {
  posts: PropTypes.array.isRequired,
}
