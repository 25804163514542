import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import Facebook from './Facebook'
import Twitter from './Twitter'
import i18n from '../../../config/i18n'

// Complete tutorial: https://www.gatsbyjs.org/docs/add-seo-component/

const SEO = ({ title, desc, banner, pathname, pageType, isArticle, node, locale }) => {
  const { site } = useStaticQuery(query)
  const { defaultTitle, defaultDescription, siteLanguage, headline, pages } = i18n[locale]

  const {
    buildTime,
    siteMetadata: { siteUrl, defaultBanner, ogLanguage, author, authorEmail, twitter, facebook },
  } = site

  const localizedPath = i18n[locale].default ? '' : `/${i18n[locale].path}`
  const homeURL = `${siteUrl}${localizedPath}`

  const seo = {
    title: title || defaultTitle,
    description: desc || defaultDescription,
    image: `${siteUrl}${banner || defaultBanner}`,
    url: `${siteUrl}${pathname || ''}`,
  }
  
  // Initial breadcrumb list
  const itemListElement = [
    {
      '@type': 'ListItem',
      item: {
        '@id': siteUrl,
        name: 'Homepage',
      },
      position: 1,
    },
  ]

  // schema.org in JSONLD format
  // https://developers.google.com/search/docs/guides/intro-structured-data
  // You can fill out the 'author', 'creator' with more data or another type (e.g. 'Organization')
  let schema = null;
  let currentYear = new Date().getFullYear();

  if (!isArticle) {
    schema = {
      '@context': 'http://schema.org',
      '@type': 'WebPage',
      url: homeURL,
      headline,
      inLanguage: siteLanguage,
      mainEntityOfPage: homeURL,
      description: seo.description,
      name: seo.title,
      author: {
        '@type': 'Person',
        name: author,
        email: authorEmail,
      },
      copyrightHolder: {
        '@type': 'Person',
        name: author,
      },
      copyrightYear: currentYear,
      creator: {
        '@type': 'Person',
        name: author,
      },
      publisher: {
        '@type': 'Organization',
        name: author,
      },
      datePublished: '2020-07-18T10:30:00+01:00',
      dateModified: buildTime,
      image: {
        '@type': 'ImageObject',
        url:  seo.image,
      },
    }
    switch (pageType) {
      case pages.categories.name:
        itemListElement.push({
          '@type': 'ListItem',
          item: {
            '@id': `${homeURL}/${pages.categories.path}`,
            name: pages.categories.name,
          },
          position: 2,
        })
        break;
      case pages.home.name:
        itemListElement.push({
          '@type': 'ListItem',
          item: {
            '@id': seo.url,
            name: seo.title,
          },
          position: 2,
        })
        break;
      default:
        break;
    }
  } else {
    schema = {
      '@context': 'http://schema.org',
      '@type': 'Article',
      author: {
        '@type': 'Person',
        name: author,
      },
      copyrightHolder: {
        '@type': 'Person',
        name: author,
      },
      copyrightYear: currentYear,
      creator: {
        '@type': 'Person',
        name: author,
      },
      publisher: {
        '@type': 'Organization',
        name: author,
        logo: {
          '@type': 'ImageObject',
          url: `${siteUrl}${defaultBanner}`,
        },
      },
      datePublished: node.first_publication_date,
      dateModified: node.last_publication_date,
      description: seo.description,
      headline: seo.title,
      inLanguage: 'en',
      url: seo.url,
      name: seo.title,
      image: {
        '@type': 'ImageObject',
        url: seo.image,
      },
      mainEntityOfPage: seo.url,
    }
    // Push blog & current article into breadcrumb list
    switch (pageType) {
      case pages.blog.name:
        itemListElement.push({
          '@type': 'ListItem',
          item: {
            '@id': `${homeURL}/${pages.blog.path}`,
            name: pages.blog.name,
          },
          position: 2,
        })
        break;
      case pages.projects.name:
        itemListElement.push({
          '@type': 'ListItem',
          item: {
            '@id': `${homeURL}/${pages.projects.path}`,
            name: pages.projects.name,
          },
          position: 2,
        })
        break;
      default:
        break;
    }

    itemListElement.push({
      '@type': 'ListItem',
      item: {
        '@id': seo.url,
        name: seo.title,
      },
      position: 3,
    })
  }

  const breadcrumb = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    description: 'Breadcrumbs list',
    name: 'Breadcrumbs',
    itemListElement,
  }

  return (
    <>
      <Helmet title={seo.title}>
        <html lang={siteLanguage} />
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        <meta name="pedro-almeida-website" content="Pedro Almeida's personal website." />
        {/* Insert schema.org data conditionally (webpage/article) + everytime (breadcrumbs) */}
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <Facebook
        desc={seo.description}
        image={seo.image}
        title={seo.title}
        type={isArticle ? 'article' : 'website'}
        url={seo.url}
        locale={ogLanguage}
        name={facebook}
      />
      <Twitter title={seo.title} image={seo.image} desc={seo.description} username={twitter} />
    </>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  banner: PropTypes.string,
  pathname: PropTypes.string,
  pageType: PropTypes.string,
  isArticle: PropTypes.bool,
  node: PropTypes.object,
  locale: PropTypes.string,
}

SEO.defaultProps = {
  title: null,
  desc: null,
  banner: null,
  pathname: null,
  pageType: '',
  isArticle: false,
  node: null,
  locale: 'en-en',
}

const query = graphql`
  query SEO {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        siteUrl
        defaultBanner: banner
        ogLanguage
        author
        authorEmail
        twitter
        facebook
      }
    }
  }
`
